import React, {Component} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Growl} from "primereact/growl";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {MERCHANT_KEY} from "../../util/constants";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {SplitButton} from "primereact/splitbutton";
import {dateToString, isEmpty, loadProfile, logOut} from "../../util/commonFunctions";
import {Calendar} from "primereact/calendar";
import {Balance} from "../MainDashboard/Balance";
import {ProgressSpinner} from "primereact/progressspinner";

export class OrderCard extends Component {

    constructor() {
        super();
        this.state = {
            username: '',
            dataTableSelection: {
                name: '',
                balance: '',
                isVirtual: '',
                numberAccount: '',
                status: '',
                reset: '',
                provider_cardid: ''
            },
            cards: [],
            showModalBuyCard: false,
            showModalRegisterCard: false,
            showModalTransferCard: false,
            resetPINDisplay: false,
            addFundsDisplay: false,
            addFundAmount: '',
            fee: '',
            total: '',
            accountSelected: null,
            accounts: [],
            displayConfirm: false,
            actionToConfirm: null,
            typeBuyCard: "virtualCardUSA",
            buyAmount: '$6.50',
            provider_accountid: '',
            processService: false,
            accountid: null,
            addFundsACHDisplay: false,
            bankList: null,
            bankListSelected: null,
            addFundACHAmount: '',
            accountSelectedACH: null,

            drop_down_cards: [],
            cardFrom: null,
            cardTo: null,
            lstAmount: [
                {label: 'Select Amount', value: null},
                {label: '10', value: '10'},
                {label: '50', value: '50'},
                {label: '100', value: '100'}
            ],
            amount: '',
            lstCurrency: [
                {label: 'Select Currency', value: null},
                {label: 'USD', value: '1'}
            ],
            currency: null,
            infoCardDisplay: false,
            cardBestInfoData: {
                PANNumber: '',
                CVVNumber: '',
                ExpirationDate: '',
                CardStatus: null,
                ExpirationYear: null,
                ExpirationMonth: null,
                ResponseCodes: null,
                VerificationDetails: null,
                Success: null
            },
            otherCards: [],
            dataTableSelectionBest: {},
            showModalTransactions: false,
            actionsViewDetailTransactions: false,
            dateStart: null,
            dateEnd: null,
            rangeDate: null,
            totalBalance: localStorage.getItem("totalBalance"),
            pendingBalance: localStorage.getItem("pendingBalance"),
            walletName: localStorage.getItem("walletName"),
            chargedYet: true,
        };
        this.actions = (rowData) => [            {
            label: 'Add Funds',
            command: () => {
                const actionDialod = "addFundsDisplay"
                this.switchDialog(rowData, actionDialod)
            }
        },
            {
                label: 'Transactions',
                command: () => {
                    const actionDialod = "showModalTransactions"
                    this.switchDialog(rowData, actionDialod)
                }
            }
        ];
        this.backendService = new BackendService();
        this.heisenbergService = new HeisenbergService();
        this.addFooterBuyCard = this.addFooterBuyCard.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.selectTypeCardToBuy = this.selectTypeCardToBuy.bind(this);
        this.addFunds = this.addFunds.bind(this);
        this.addFundsACH = this.addFundsACH.bind(this);
        this.validateAddFundsACH = this.validateAddFundsACH.bind(this);
        this.loadBestCards = this.loadBestCards.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.activateCard = this.activateCard.bind(this);
        this.balanceTemplate1 = this.balanceTemplate1.bind(this);
        this.balanceTemplate2 = this.balanceTemplate2.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.upperCaseTemplate = this.upperCaseTemplate.bind(this);
        this.infoCard = this.infoCard.bind(this);
        this.switchDialog = this.switchDialog.bind(this);

    }

    componentDidMount() {
        loadProfile(this, this.backendService, null).then(resp => {});
        this.setState({chargedYet: false});
        this.backendService.getProfile().then(profileResp => {
            if (profileResp.status === 'success') {
                localStorage.setItem('profile', JSON.stringify(profileResp.data));
                const profileData = profileResp.data;
                if (profileData !== null) {
                    const rows = profileData.array_accounts;
                    const accounts = [];
                    rows.forEach(function (element) {
                            if (element.currency === "USD") {
                                let balance = Number(element.last_balance).toFixed(2);
                                accounts.push({label: element.label, balance: balance, value: element.provider_accountid})
                            }
                        }
                    );
                    this.setState({accounts, accountid: accounts[0]});
                    if (profileData.accounts !== null) {
                        this.setState({
                            provider_accountid: profileData.accounts.USD.provider_accountid,
                            username: profileData.email,
                        });
                    }
                    this.loadCards();
                    //this.loadBestCards();
                }
            }
        }).catch(error => {
            console.log("error: ", error);
            if (error.response.status === 401) {
                logOut(this.props.history);
            } else if (this.growl !== null) this.growl.show({
                severity: 'error',
                summary: 'Error',
                detail: "User or password is wrong"
            });
        });
    }

    loadCards() {
        const cards = [];
        const drop_down_cards = [{label: 'Select Card', value: null}];
        /*{label: 'Select Card', value: null}*/
        this.backendService.getVirtualCards().then(resp => {
            if (resp.status === 'success') {
                resp.data.forEach(element => cards.push(element));
                resp.data.forEach(element => drop_down_cards.push({label: element.label, value: element.id}));
                this.backendService.getPhysicalCards().then(resp => {
                    if (resp.status === 'success') {
                        resp.data.forEach(element => cards.push(element));
                        resp.data.forEach(element => drop_down_cards.push({label: element.label, value: element.id}));
                        this.setState({cards, drop_down_cards});
                        this.setState({chargedYet: true});
                    }
                }).catch(error => {
                    console.log("Error load catalogs: ", error);
                });
            }
        }).catch(error => {
            console.log("Error load catalogs: ", error);
        });
        const bankList = [];
        const profileData = JSON.parse(localStorage.getItem('profile'));
        if (profileData?.accounts?.USD?.provider_accountid !== null && profileData?.accounts?.USD?.provider_accountid !== undefined) {
            this.backendService.getListBanks(profileData.accounts?.USD?.provider_accountid).then(resp => {
                if (resp["status code"] === 200) {
                    if (resp.data !== null) {
                        resp.data.forEach(element => {
                            bankList.push({
                                label: element.BankAccountName,
                                value: {name: element.BankAccountName, token: element.BankAccountToken}
                            })
                        });
                    }
                    this.setState({bankList});
                }
            }).catch(error => {
                console.log("ERROR: ", error);
                this.growl.show({severity: 'error', summary: 'Error', detail: "Error get profile"});
            });
        }
    }

    loadBestCards = () =>  {
        this.setState({processService: true});
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            "merchant_key": MERCHANT_KEY,
            "username": profileData.email,
            "provider_cardid": this.state.dataRow.provider_cardid,
            "start_date": dateToString(this.state.rangeDate[0]),
            "end_date": dateToString(this.state.rangeDate[1]),
            "account_id": profileData.array_accounts[0].provider_accountid,

        };
        this.backendService.cardHistory(params).then(resp => {
            if (resp.status === 'OK') {
                const otherCards = resp.data.data['Transactions'];
                otherCards.forEach(function (element) {
                    if (element.TransactionDate != null) {
                        element.TransactionDate = element.TransactionDate.substring(0, 10);
                    }
                });
                this.setState({otherCards, actionsViewDetailTransactions: true, rangeDate: null});

            }
            this.setState({displayConfirm: false, processService: false, showModalTransactions: false});
        }).catch(error => {
            this.growl.show({severity: 'error', summary: 'Error'});
            this.setState({showModalTransactions: false, rangeDate: null})
        });
    }
    showModal = modal => () => {
        this.setState({[modal]: true});
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
            dateStart: null,
            dateEnd: null,
            accountSelected: null,
            addFundAmount: null,
            accountSelectedACH: null,
            bankListSelected: null,
            addFundACHAmount: null
        });
    }
    addFooterBuyCard = () => {
        this.setState({processService: true, chargedYet: false});
        if (this.state.typeBuyCard === "") {
            this.growl.show({severity: 'error', summary: 'Type card is mandatory', detail: "Select a type card"});
            this.setState({displayConfirm: false, processService: false});
        } else {
            if (this.state.typeBuyCard === "virtualCardOneTimeLoad" ||
                this.state.typeBuyCard === "virtualCardInternational" ||
                this.state.typeBuyCard === "virtualCardUSA") {
                const params = {
                    "account_id": this.state.accountid.value.toString(),
                    "amount": 6.50
                };
                this.setState({showModalBuyCard: false, processService: false});
                this.backendService.addVirtualCard(params).then(resp => {
                    if (resp.status === 'success') {
                        this.setState({chargedYet: true});
                        this.loadCards();
                        this.growl.show({severity: 'success', summary: 'Purchased card', detail: "Success"});
                        setTimeout(() => {
                            this.componentDidMount()
                        }, 1000);
                    }
                }).catch(error => {
                    this.setState({chargedYet: true});
                    this.growl.show({severity: 'error', summary: 'Error buy card', detail: error.response.data.msg});
                    console.log("Error load catalogs: ", error);
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 1000);
                });
            } else if (this.state.typeBuyCard === "physicalCardInternational" ||
                this.state.typeBuyCard === "physicalCardUSA") {
                const params = {
                    "account_id": this.state.provider_accountid.toString(),
                    "amount": 6.50
                };
                this.setState({showModalBuyCard: false, processService: false});
                this.backendService.addNewPhysicalCard(params).then(resp => {
                    if (resp.status === 'success') {
                        loadProfile(this, this.backendService, null).then(resp => {});
                        this.setState({chargedYet: true});
                        this.loadCards();
                        this.growl.show({severity: 'success', summary: 'Purchased card', detail: "Success"});
                    }
                }).catch(error => {
                    this.setState({chargedYet: true});
                    loadProfile(this, this.backendService, null).then(resp => {});
                    this.growl.show({severity: 'error', summary: 'Error buy card', detail: error.response.data.msg});
                    console.log("Error load catalogs: ", error);
                });
            } else {
                this.setState({chargedYet: true});
                this.growl.show({severity: 'warning', summary: 'Working', detail: "Working on the process"});
                this.setState({showModalBuyCard: false, processService: false});
            }
        }
    }


    infoCard = (rowData) => {
        this.setState({chargedYet: false});
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
            account_id: profileData.array_accounts[0].provider_accountid,
            provider_cardid: rowData.provider_cardid
        }
        this.backendService.infoCard(params).then(resp => {
            if (resp.status === 'OK') {
                debugger
                const yearData = resp.data.data.expYear.slice(-2);
                const mthData = resp.data.data.expMonth;
                const expirationDateFormat = `${mthData}/${yearData}`;
                this.setState({ chargedYet: true, expirationDateFormat: expirationDateFormat, infoCardDisplay: true, cardBestInfoData: resp.data.data });
            }
        }).catch(error => {
            this.setState({ chargedYet: true});
            this.growl.show({
                severity: 'error',
                summary: 'Error',
            });
        });
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.status_provider === "ACTIVATE" ?
                    <SplitButton
                        label="Info"
                        className="p-button-raised"
                        onClick={() => this.infoCard(rowData)}
                        model={this.actions(rowData)}
                    />
                    :
                    <Button label="Activate Card" className="p-button-raised" onClick={() =>this.activateCard(rowData)}/>
                }
            </React.Fragment>
        );
    }

    switchDialog = (rowData, dialogAction) => {
        const dataRow = rowData;
        this.setState({dataRow});
        if (dialogAction === "showModalTransactions") {
            this.setState({showModalTransactions: true})
        } else {
            this.setState({addFundsDisplay: true})
        };
    }

    activateCard (rowData) {
        this.setState({chargedYet: false});
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
            provider_cardid: rowData.provider_cardid,
            account_id: profileData.array_accounts[0].provider_accountid
        }
        this.backendService.activateCard(params).then(resp => {
            if (resp.status === "OK") {
                let respDataList = Object.entries(resp.data.data).map(([key, value]) => `${key}: ${value}`).join('\n');
                let detailElem = respDataList.split('\n').map((item, i) => <p key={i}>{item}</p>);
                this.growl.show({severity: 'info', summary: 'Card activated successfully', detail: detailElem, life: 9000});
                this.loadCards();
            } else {
                this.setState({chargedYet: true});
                this.growl.show({
                    severity: 'error',
                    summary: 'Error in activate card',
                    detail: resp.data.msg,
                    life: 7000
                });
            }
        }).catch(error => {
            this.setState({chargedYet: true});
            console.log("Error", error);
        });
    }

    confirmationDialog = (nameModal, showModal, action) => {
        if ((nameModal === 'addFundsACHDisplay' && this.validateAddFundsACH()) ||
            nameModal !== 'addFundsACHDisplay') {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        }
    }

    selectTypeCardToBuy = (type) => {
        this.setState({typeBuyCard: type});
    }

    addFunds = modal => () => {
        const {
            accountSelected,
            addFundAmount,
        } = this.state;
        if (accountSelected !== null && addFundAmount !== '') {
            this.setState({processService: true});
            const params = {
                //merchant_key: MERCHANT_KEY,
                //type_transfer: "LUT",
                //type_provider: 1,
                //type_receiver: 9,
                //username: username,
                sender_account: accountSelected.toString(),
                receiver_card: this.state.dataRow.provider_cardid,
                amount: Number(addFundAmount),
                test: true
                //receiver_pre_balance: "0",
                //receiver_last_balance: "0",
                //receiver_fee_estimate: "0",
                //currency: "USD",
            };
            this.backendService.postAddFunds(params).then(resp => {
                if (resp.status === 'success') {
                    this.growl.show({severity: 'success', summary: 'Success', detail: "Funds are loaded"});
                    this.setState({
                        [modal]: false,
                        displayConfirm: false,
                        processService: false
                    });
                    setTimeout(() => {
                        this.componentDidMount()
                    }, 1000);
                } else {
                    this.setState({
                        [modal]: false,
                        displayConfirm: false,
                        processService: false
                    });
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Service error"});
                }
            }).catch(error => {
                this.setState({
                    [modal]: false,
                    displayConfirm: false,
                    processService: false
                });
                console.log("Error load catalogs: ", error);
                this.growl.show({severity: 'error', summary: 'Error', detail: error.message});
            });
        } else {
            this.setState({
                [modal]: false,
                displayConfirm: false,
                processService: false
            });
            this.growl.show({severity: 'error', summary: 'Error', detail: "Account is mandatory, Amount is mandatory"});
            this.setState({
                displayConfirm: false,
            });
        }
    }

    addFundsACH = modal => () => {
        if (this.validateAddFundsACH()) {
            this.setState({processService: true});
            const {
                addFundACHAmount,
                bankListSelected,
                accountSelectedACH,
                dataTableSelection
            } = this.state;
            const profileData = JSON.parse(localStorage.getItem('profile'));
            const params = {
                merchant_key: MERCHANT_KEY,
                type_transfer: "LUT",
                type_provider: 1,
                type_receiver: 9,
                username: profileData.email,
                sender_account: accountSelectedACH,
                receiver_card: dataTableSelection.provider_cardid,
                best_bank_name: bankListSelected.name,
                bank_account_token: bankListSelected.token,
                amount: addFundACHAmount,
                currency: "USD",
                test: true
            };


            this.backendService.addFundsACHToCard(params).then(resp => {
                if (resp.data.code === "200") {
                    this.loadCards();
                    this.growl.show({severity: 'success', summary: 'Funds updated', detail: "Success", life: 7000});
                } else {
                    this.growl.show({
                        severity: 'error',
                        summary: 'Error update funds from ACH',
                        detail: "Error service",
                        life: 7000
                    });
                }
                this.setState({
                    [modal]: false,
                    displayConfirm: false,
                    processService: false
                });
            }).catch(error => {
                console.log("Error load catalogs: ", error);
            });

            console.log("addFundsACHToCard: ", params);
        }
    }

    validateAddFundsACH() {
        const {
            addFundACHAmount,
            bankListSelected,
            accountSelectedACH
        } = this.state;
        if (isEmpty(addFundACHAmount) ||
            isEmpty(bankListSelected) ||
            isEmpty(accountSelectedACH)) {
            this.growl.show({severity: 'error', summary: 'Error', detail: "All fields are mandatory!"});
            return false;
        }
        return true;
    }
    changeHandler = event => {
        this.setState({accountid: event.value});
    };
    balanceTemplate1 = (rowData) => {
        if  (rowData.last_balance !== 0) {
            let value = (rowData.last_balance);
            let formattedValue = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value);
            return formattedValue;
        }
        else {
            let formattedValue = '$00.00'
            return formattedValue;
        }
    };
    balanceTemplate2 = (rowData) => {
        if  (rowData.amount !== 0) {
            let value = (rowData.amount/100);
            let formattedValue = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value);
            return formattedValue;
        }
        else {
            let formattedValue = '$00.00'
            return formattedValue;
        }
    };
    itemTemplate(option) {
        return (
            <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {option.label}
                    <span style={{color: "#E0C46C"}}>${option.balance}</span>
                </div>
            </React.Fragment>
        );
    }

    upperCaseTemplate = (rowData) => {
        return <div>{rowData.TransactionType ? rowData.TransactionType.charAt(0).toUpperCase() + rowData.TransactionType.slice(1).toLowerCase() : ''}</div>;
    };

    handleAmountChange = (e) => {
        const addFundAmount = Number(e.target.value);
        const fee = addFundAmount * 0.03;
        const total = addFundAmount + fee;
        this.setState({ addFundAmount, fee, total });
    }

    render() {
        const featuresCard = (
            <div className="p-grid" style={{lineHeight: "normal", padding: 12}}>
                <div className="p-col-6"><span className="text-big">Card opening fee</span></div>
                <div className="p-col-6"><span className="text-big">Monthly fee</span></div>
                <div className="p-col-6"><span>Up to $5 in stores, $0 online</span></div>
                <div className="p-col-6"><span>None</span></div>
                <div className="p-col-6"><span className="text-big">Maximum balance</span></div>
                <div className="p-col-6"><span className="text-big">Cash reload fee</span></div>
                <div className="p-col-6"><span>$100,000</span></div>
                <div className="p-col-6"><span>$0 at Walmart, up to $3.95 at other retailers</span></div>
                <div className="p-col-6"><span className="text-big">ATM withdrawal fee</span></div>
                <div className="p-col-6"><span className="text-big">Foreign transaction fee</span></div>
                <div className="p-col-6"><span>$0 in-network at MoneyPass® ATMs; otherwise $2.50 per withdrawal, plus ATM operator fee</span></div>
                <div className="p-col-6"><span>None</span></div>
                <div className="p-col-6"><span className="text-big">Rewards</span></div>
                <div className="p-col-6"><span className="text-big">Mobile check deposit</span></div>
                <div className="p-col-6"><span>N/A</span></div>
                <div className="p-col-6"><span>Yes</span></div>
            </div>
        );

        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check"
                        onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        )

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw', background: "#1c2155"}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        )

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                {nameModal !== 'infoCardDisplay' &&
                    <Button icon="pi pi-check"
                            onClick={(e) => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                            label={textAction}/>
                }
            </div>
        )
        const dialogFooterTransactions =  (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal('showModalTransactions')} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check"
                        onClick={this.loadBestCards}
                        label="Search"/>
            </div>
        )

        const dialogRegisterCard = (
            <Dialog header="Register Card" visible={this.state.showModalRegisterCard} modal={true}
                    style={{width: '50vw',background: "#1c2155"}}
                    contentStyle={{minHeight: '200px'}}
                    footer={dialogFooter('showModalRegisterCard', this.hideModal('showModalRegisterCard'), 'Register')}
                    onHide={this.hideModal('showModalRegisterCard')}>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-4 center-card">
                        Card number: *
                    </div>
                    <div className="p-col-12 p-md-8">
                        <InputText placeholder="Card number"/>
                    </div>
                    <div className="p-col-12 p-md-4 center-card">
                        Cardholder Name: *
                    </div>
                    <div className="p-col-12 p-md-8">
                        <InputText placeholder="Cardholder Name"/>
                    </div>
                    <div className="p-col-12 p-md-4 center-card">
                        Alias: *
                    </div>
                    <div className="p-col-12 p-md-8">
                        <InputText placeholder="Alias"/>
                    </div>
                </div>
            </Dialog>
        );

        const dialogBeneficiary = (
            <Dialog header="Buy a New Card" visible={this.state.showModalBuyCard} modal={true}
                    style={{width: '60vw', background: "#1c2155"}}
                    contentStyle={{maxHeight: '420px'}}
                    footer={dialogFooter('showModalBuyCard', this.addFooterBuyCard, 'Buy')}
                    onHide={this.hideModal('showModalBuyCard')}>
                <div className="p-grid">
                    <div className="p-col-12 p-sm-12 p-md-6 p-lg-12 ta-rigth">
                        <div className="card card-w-title">
                            <div className="p-field p-grid">
                                <label htmlFor="buyAmount" className="p-col-fixed mt-6" style={{width: '100px'}}>Wallet Name:</label>
                                <div className="p-col">
                                    <Dropdown options={this.state.accounts}
                                              placeholder="Wallet Name"
                                              optionLabel="label"
                                              name="label"
                                              value={this.state.accountid}
                                              onChange={this.changeHandler}
                                              disabled={false}
                                              autoWidth={false} />
                                </div>
                                <label htmlFor="buyAmount" className="p-col-fixed mt-6" >Amount*:</label>
                                <div className="p-col">
                                    <InputText id="buyAmount" style={{width: '150px'}} value={this.state.buyAmount}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="p-col-12 p-sm-12 p-md-6 p-lg-6 ta-center"></div>
                    <div className="p-col-12 borderBottom">
                        <div className="p-grid">
                            <div className="p-col-6 p-sm-6 p-md-4 p-lg-4 ta-center p-fluid">
                                <Button label="Virtual Card One Time Load" onClick={() => this.selectTypeCardToBuy("virtualCardOneTimeLoad")}
                                        className={this.state.typeBuyCard === "virtualCardOneTimeLoad" ? "p-button-active" : ""}/>
                                <img src="https://white-labels-donations.s3.amazonaws.com/CardBlueFront.png"
                                     className="center-card-img" alt="cardBlue" />
                                <Button label="Learn More" className="p-button-secondary" />
                                <div style={{lineHeight:"normal"}}>
                                    <label className="textSmall">
                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam condimentum metus est, nec condimentum tellus viverra et. Integer at urna fringilla, pellentesque magna id, scelerisque ex.
                                    </label>
                                </div>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-8 p-lg-8 features-styles-select-creditCard">
                                {featuresCard}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 borderBottom">
                        <div className="p-grid">
                            <div className="p-col-6 p-sm-6 p-md-4 p-lg-4 ta-center p-fluid">
                                <Button label="Virtual Card International" onClick={() => this.selectTypeCardToBuy("virtualCardInternational")}
                                        className={this.state.typeBuyCard === "virtualCardInternational" ? "p-button-active" : ""}/>
                                <img src="https://white-labels-donations.s3.us-east-2.amazonaws.com/CardRedFront.png"
                                     className="center-card-img" alt="cardRed"/>
                                <Button label="Learn More" className="p-button-secondary" />
                                <div style={{lineHeight:"normal"}}>
                                    <label className="textSmall">
                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam condimentum metus est, nec condimentum tellus viverra et. Integer at urna fringilla, pellentesque magna id, scelerisque ex.
                                    </label>
                                </div>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-8 p-lg-8 features-styles-select-creditCard">
                                {featuresCard}
                            </div>
                        </div>
                    </div>*/}
                    <div className="p-col-12 borderBottom">
                        <div className="p-grid">
                            <div className="p-col-6 p-sm-6 p-md-4 p-lg-4 ta-center p-fluid">
                                <Button label="Virtual card USA" onClick={() => this.selectTypeCardToBuy("virtualCardUSA")}
                                        className={this.state.typeBuyCard === "virtualCardUSA" ? "p-button-active" : ""}/>
                                <label className="textSmall">Apple Pay, Samsung Pay</label>
                                <img src="assets/ewallet/img/virtualCard.png" style={{borderRadius: '1em'}}
                                     className="center-card-img" alt="cardBlue" />
                                <Button label="Learn More" className="p-button-secondary" style={{marginTop: '10px'}} />
                                <div style={{lineHeight:"normal"}}>
                                    <label className="textSmall">
                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam condimentum metus est, nec condimentum tellus viverra et. Integer at urna fringilla, pellentesque magna id, scelerisque ex.
                                    </label>
                                </div>
                            </div>
                            <div className="p-col-6 p-sm-6 p-md-8 p-lg-8 features-styles-select-creditCard">
                                {featuresCard}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        // eslint-disable-next-line
        const dialogTransfer = (
            <Dialog header="Transfer $ to Companion Card" visible={this.state.showModalTransferCard} modal={true}
                    style={{width: '50vw',background: "#1c2155"}}
                    contentStyle={{minHeight: '280px'}}
                    footer={dialogFooter('showModalTransferCard', this.hideModal('showModalTransferCard'), 'Transfer')}
                    onHide={this.hideModal('showModalTransferCard')}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="your-balance_card">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-4 center-card">
                                    From Card: *
                                </div>
                                <div className="p-col-12 p-md-8">
                                    <Dropdown
                                        options={this.state.drop_down_cards}
                                        value={this.state.cardFrom}
                                        onChange={event => this.setState({cardFrom: event.value})} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <p>To Card:</p>
                                </div>
                                <div className="p-col-12 p-md-8">
                                    <Dropdown
                                        options={this.state.drop_down_cards}
                                        value={this.state.cardTo}
                                        onChange={event => this.setState({cardTo: event.value})} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-4 center-card">
                                    Currency:
                                </div>
                                <div className="p-col-12 p-md-8">
                                    <Dropdown
                                        options={this.state.lstCurrency}
                                        value={this.state.currency}
                                        onChange={event => this.setState({currency: event.value})} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-4 center-card">
                                    Amount: *
                                </div>
                                <div className="p-col-12 p-md-8">
                                    <InputText value={this.state.amount}
                                               type="number"
                                               style={{padding:10}}
                                               onChange={(e) => this.setState({amount: e.target.value})} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const dialogRestConfirmation = (
            <Dialog header="Reset confirmation" visible={this.state.resetPINDisplay} modal={true}
                    style={{width: '350px',background: "#1c2155"}}
                    footer={dialogFooter('resetPINDisplay', this.hideModal('resetPINDisplay'), 'Reset')}
                    onHide={this.hideModal('resetPINDisplay')}>
                <p>Card name: {this.state.dataTableSelection?.name_on_card}</p>
                <p>Card balance: {this.state.dataTableSelection?.card_number}</p>
                <p>User: {this.state.dataTableSelection?.user}</p>
            </Dialog>
        );

        const dialogInformation = (
            <Dialog header="Information" visible={this.state.infoCardDisplay} modal={true}
                    style={{width: '350px', background: "#1c2155"}}
                    footer={dialogFooter('infoCardDisplay', this.hideModal('infoCardDisplay'), 'Close')}
                    onHide={this.hideModal('infoCardDisplay')}>
                <p><b>Card Number: </b>{this.state.cardBestInfoData.cardNumber}</p>
                <p><b>Expiration Date: </b>{this.state.expirationDateFormat}</p>
                <p><b>Expiration Year: </b>{this.state.cardBestInfoData.expYear}</p>
                <p><b>Expiration Month: </b>{this.state.cardBestInfoData.expMonth}</p>
                <p><b>CVV: </b>{this.state.cardBestInfoData.cvv}</p>
                {/*<p><b>ResponseCodes: </b>{this.state.cardBestInfoData.CodeDesc}</p>
                <p><b>CardStatus: </b>{this.state.cardBestInfoData.StatusID}</p>
                <p><b>VerificationDetails: </b>{this.state.cardBestInfoData.VerificationDetails}</p>*/}
            </Dialog>
        );

        const dialogFunds = (
            <div>
            <Dialog header="Add Funds" visible={this.state.addFundsDisplay} modal={true}
                        style={{width: '450px',background: "#1c2155"}}

                        footer={dialogFooter('addFundsDisplay', this.addFunds('addFundsDisplay'), 'Add')}
                        onHide={this.hideModal('addFundsDisplay')}>
                    <div>
                        <div className="p-field p-grid p-fluid">
                            <label htmlFor="account" className="p-col-fixed mt-6"
                                   style={{width: '100px'}}>Account:</label>
                            <div className="p-col">
                                <Dropdown
                                    scrollHeight="100px"
                                    options={this.state.accounts}
                                    value={this.state.accountSelected}
                                    itemTemplate={this.itemTemplate}
                                    placeholder="Select a account"
                                    onChange={(e) => this.setState({accountSelected: e.value})} autoWidth={false}/>
                            </div>
                        </div>
                        <div className="p-field p-grid p-fluid">
                            <label htmlFor="amount" className="p-col-fixed mt-6"
                                   style={{width: '100px'}}>Amount:</label>
                            <div className="p-col">
                                <InputText value={this.state.addFundAmount}
                                           placeholder="Amount"
                                           onChange={this.handleAmountChange}
                                           style={{padding: 10}}
                                />
                            </div>
                        </div>
                        <div className="p-field p-grid p-fluid">
                            <label htmlFor="fee" className="p-col-fixed mt-6" style={{width: '100px'}}>Fee:</label>
                            <div className="p-col">
                                <InputText value={this.state.fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                           placeholder="Fee"
                                           readOnly
                                           style={{padding: 10}}
                                />
                            </div>
                        </div>
                        <div className="p-field p-grid p-fluid">
                            <label htmlFor="total" className="p-col-fixed mt-6" style={{width: '100px'}}>Total:</label>
                            <div className="p-col">
                                <InputText value={this.state.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                           placeholder="Total"
                                           readOnly
                                           style={{padding: 10}}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Add Funds ACH" visible={this.state.addFundsACHDisplay} modal={true}
                        style={{width: '450px', background: "#1c2155"}}
                        contentStyle={{height: 240}}
                        footer={dialogFooter('addFundsACHDisplay', this.addFundsACH('addFundsACHDisplay'), 'Add')}
                        onHide={this.hideModal('addFundsACHDisplay')}>
                    <div>
                        <div className="p-field p-grid p-fluid mt-10">
                            <label htmlFor="account" className="p-col-fixed mt-6"
                                   style={{width: '100px'}}>Account:</label>
                            <div className="p-col">
                                <Dropdown
                                    scrollHeight="100px"
                                    options={this.state.accounts}
                                    value={this.state.accountSelectedACH}
                                    placeholder="Select a account"
                                    onChange={(e) => this.setState({accountSelectedACH: e.value})} autoWidth={false}/>
                            </div>
                        </div>
                        <div className="p-field p-grid p-fluid mt-10">
                            <label htmlFor="bankList" className="p-col-fixed mt-6" style={{width: '100px'}}>Bank
                                list:</label>
                            <div className="p-col">
                                <Dropdown
                                    scrollHeight="100px"
                                    options={this.state.bankList}
                                    value={this.state.bankListSelected}
                                    placeholder="Select a account"
                                    onChange={(e) => this.setState({bankListSelected: e.value})} autoWidth={false}/>
                            </div>
                        </div>
                        <div className="p-field p-grid p-fluid mt-10">
                            <label htmlFor="amountACH" className="p-col-fixed mt-6" style={{width:'100px'}}>Amount:</label>
                            <div className="p-col">
                                <InputText value={this.state.addFundACHAmount}
                                           placeholder="Amount"
                                           type="number"
                                           onChange={(e) => this.setState({addFundACHAmount: e.target.value})}
                                           style={{padding:10}}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );

        const dialogTransactions = (
            <Dialog header="Transactions" visible={this.state.showModalTransactions} modal={true}
                    style={{width: '30vw',background: "#1c2155"}}
                    contentStyle={{minHeight: '400px'}}
                    footer={dialogFooterTransactions}
                    onHide={this.hideModal('showModalTransactions')}>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-4 center-card">
                        Range Date: *
                    </div>
                    <div className="p-col-12 p-md-8">
                        <Calendar id="rangeDate"
                                  tooltip= "Enter range"
                                  tooltipOptions={{position: 'top'}}
                                  placeholder="Range Date"
                                  dateFormat="yy-mm-dd"
                                  name="rangeDate"
                                  selectionMode="range"
                                  value={this.state.rangeDate}
                                  onChange={(e) => this.setState({ rangeDate: e.value })}
                                  showIcon monthNavigator/>
                    </div>
                </div>
            </Dialog>
        );
        const formatDate = rowData => {
            const date = new Date(rowData.dateCreated);
            return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
        }

        const tabViewDetailTransactions = (
            <div className="card card-w-title ">
                <div className="p-grid">
                    <div className="p-col-10">
                        <h2>Transaction history</h2>
                    </div>
                    <div className="p-col-2 ta-right">
                        <i className="pi pi-times" style={{'fontSize': '1.5em', color: '#f7ab32', cursor: 'pointer'}}
                           onClick={this.hideModal('actionsViewDetailTransactions')}/>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col-12">
                            <DataTable value={this.state.otherCards} selectionMode="single" paginator={true} rows={10}
                                       selection={this.state.dataTableSelectionBest} onSelectionChange={event => this.setState({dataTableSelectionBest: event.value})}
                                       responsive={true}>
                                <Column field='transID' header='Transaction Number' className="ta-center"/>
                                <Column field='dateCreated' header='Date' body={formatDate} className="ta-center"/>
                                <Column field='description' header='Description' className="ta-center"/>
                                <Column field="transType" header="Transaction Type" className="ta-center"/>
                                <Column field='amount' header='Amount' body={this.balanceTemplate2}className="ta-center"/>
                                {/*<Column field='Fees' header='Fees' className="ta-center"/>
                                <Column field='BlockedAmount' header='Charged Amount' className="ta-center"/>
                                <Column field='AvailableBalance' header='Available Balance' className="ta-center"/>*/}

                                {/*<Column field='mSwipeTransID' header='Transaction Number' className="ta-center"/>
                                <Column field='TransactionDate' header='Date' className="ta-center"/>
                                <Column field='MerchantName' header='Description' className="ta-center"/>
                                <Column field="TransactionType" header="Transaction Type" body={this.upperCaseTemplate} className="ta-center"/>
                                <Column field='Amount' header='Amount' className="ta-center"/>
                                <Column field='Fees' header='Fees' className="ta-center"/>
                                <Column field='BlockedAmount' header='Charged Amount' className="ta-center"/>
                                <Column field='AvailableBalance' header='Available Balance' className="ta-center"/>*/}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px', width: '25%'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-8 p-md-8">
                                <h1 className="mb-20">Manage Card</h1>
                            </div>
                            {!this.state.chargedYet ? '' :
                                <div className="p-col-12 p-lg-4 p-md-4">
                                    <Balance totalBalance={this.state.totalBalance}
                                             pendingBalance={this.state.pendingBalance}
                                             walletName={this.state.walletName}/>
                                    <div className="p-col-12 text-right">
                                        <Button label="Order Card" onClick={this.showModal('showModalBuyCard')}/>
                                    </div>
                                </div>

                            }
                            <div className="p-col-12 text-right">
                                {/*<Button label="Register" onClick={this.showModal('showModalRegisterCard')}/>
                                <Button label="Transfer" onClick={this.showModal('showModalTransferCard')}/>}*/}
                                <div className="grid">
                                    {this.state.chargedYet ? '' :
                                        <div className="col-9 ta-right" style={{position: "relative", marginTop: -60, paddingRight: 20,}}>
                                            <ProgressSpinner style={{width: '150px', height: '60px'}}
                                                             animationDuration=".7s"/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="p-col-12">
                                <DataTable value={this.state.cards} selectionMode="single" paginator={true} rows={10} className="mt-20"
                                           selection={this.state.dataTableSelection}
                                           onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                           responsive={true}>
                                    <Column field='card_number' header='Card Number' className="ta-center"/>
                                    {/*<Column field='label' header='Label' className="ta-center"/>*/}
                                    <Column field='name_on_card' header='Name' className="ta-center"/>
                                    <Column field="last_balance" header="Balance" body={this.balanceTemplate1} className="ta-center"/>
                                    <Column
                                        field='is_virtual'
                                        header='Card Type'
                                        className="ta-center"
                                        body={(rowData) => rowData.is_virtual ? "Virtual" : rowData.is_virtual.toString()}
                                    />
                                    <Column field='status_provider' header='Status' className="ta-center"/>
                                    <Column body={this.actionBodyTemplate} header='Actions' style={{width:'210px'}} className="ta-center"/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    {this.state.actionsViewDetailTransactions && tabViewDetailTransactions}
                </div>
                {confirmDialog}
                {dialogBeneficiary}
                {dialogRegisterCard}
                {dialogTransfer}
                {dialogRestConfirmation}
                {dialogInformation}
                {dialogFunds}
                {dialogTransactions}

                <ValidationKYC history={this.props.history}/>
            </div>
        )
    }
}
